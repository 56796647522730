<template>
    <form novalidate=true @submit.prevent="enviarFormulario">
        <div class="row mb-3" v-if="!isModal">
            <div class="col-6">
                <h2 class="text-dark">
                    {{ titulo }}
                </h2>
            </div>
		</div>
        <div class="row">
            <div class="row col-md-12">
                <div class="col-md-4 ">
                    <div class="form-group">
                        <label for="name" class="form_label ">Nombre</label>
                        <input class="form-control" type="text" v-model="form.name" :class="{'is-invalid': validaciones.name.error}" required="required" maxlength="255" id="name">
                    </div>
                </div>
                <div class="col-md-4 ">
                    <div class="form-group">
                        <label for="periodicity" class="form_label ">Periodicidad</label>
                        <v-select 
                            id="periodicity"
                            class="bg-white" 
                            :class="{'is-invalid': validaciones.periodicity_id.error}"
                            label="name"
                            v-model="form.periodicity_id"
                            :options="periodicities"
                            :reduce="periodicity => periodicity.id"
                            :clearable="false"
                            />
                    </div>
                </div>
                <div class="col-md-4 ">
                    <div class="form-group">
                        <label for="events" class="form_label ">Eventos por periódo</label>
                        <input class="form-control" type="number" v-model="form.event_numbers" id="events" :class="{'is-invalid': validaciones.event_numbers.error}" >
                    </div>
                </div>
                <div class="form-group col-md-4">
                    <label for="status">¿Advertir?</label>
                    <div class="w-100">
                        <p-check class="p-default p-bigger" color="primary-o" v-model="form.has_warning_time" />
                    </div>
                </div>
                <div class="form-group col-md-4 tiempo-advertencia-group" :class="{'d-none': !form.has_warning_time}">
                    <label>Tiempo de advertencia</label>
                    <div class="row">
                        <div class="col-6 form-group">
                            <input class="form-control" type="number" v-model="form.warning_time_quantity" :class="{'is-invalid': validaciones.warning_time_quantity.error}">
                        </div>
                        <div class="col-6 form-group">
                            <v-select 
                            class="bg-white" 
                            :class="{'is-invalid': validaciones.warning_time_unit_id.error}"
                            label="name"
                            v-model="form.warning_time_unit_id"
                            :options="timeUnits"
                            :reduce="time => time.id"
                            :clearable="false"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-3" v-if="!isModal">
            <div class="col-12">
                <hr />
            </div>
            <div class="col-6 text-left">
                <button type="button" class="btn btn-outline-dark" title="Volver" @click="volver">
                    <font-awesome-icon icon="arrow-left" /> Volver
                </button>
            </div>
            <div class="col-6 text-right">
                <button type="submit" class="btn btn-success" title="Guardar" v-loading-btn="{loading: loading}" >
                    <font-awesome-icon icon="save" /> Guardar
                </button>
            </div>
        </div>
    </form>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft, faSave, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Validaciones } from '../../utils/funciones';

library.add([faArrowLeft, faSave, faPlus]);

export default {
    name: 'ItemsForm',
    props: {
        save: {
            type: Boolean,
            default: false
        },
        isModal: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        save: function (value) {
            if(value) {
                this.enviarFormulario();
            }
        }
    },
    data: () => {
        return {
            titulo: 'Crear ítem',
            estaEditando: false,
            loading: false,
            form: {
                id: null,
                name: null,
                periodicity_id: null,
                event_numbers: null,
                has_warning_time: true,
                warning_time_quantity: null,
                warning_time_unit_id: null
            },
            periodicities: [],
            timeUnits: [],
            validaciones: {
                name: {
                    error: false,
                    reglas: 'not-empty'
                },
                periodicity_id: {
                    error: false,
                    reglas: 'not-empty'
                },
                event_numbers: {
                    error: false,
                    reglas: 'not-empty|number',
                },
                warning_time_quantity: {
                    error: false,
                    reglas: 'not-empty|number',
                    requires: function(form) {
                        return form.has_warning_time;
                    }
                },
                warning_time_unit_id: {
                    error: false,
                    reglas: 'not-empty',
                    requires: function(form) {
                        return form.has_warning_time;
                    }
                },
            }
        }
    },
    methods: {
        volver() {
            this.$router.push({path: '/items'});
        },
        enviarFormulario() {
            let self = this;
            self.$emit('update:save', true);
            self.loading = true;

            Validaciones.reset(self.validaciones);
            Validaciones.validar(self.form, self.validaciones);

            let hasError = Validaciones.hasError(self.validaciones);

            console.log('self.form', self.form);

            if(hasError) {
                self.$emit('update:save', false);
                self.$toastr.e('El formulario contiene errores', "Error validación");
            } else {
                const data = {...self.form };

                let $promise = null;
                if(self.estaEditando) {
                    delete data.id;
                    $promise = self.axios.put(`items/${self.$route.params.id}`, data);
                } else {
                    $promise =  self.axios.post(`items`, data);
                }

                $promise
                .then(function(response) {
                    self.$emit('update:save', false);
                    if(response.error) {
                        self.loading = false;
                        self.$toastr.e(response.message, "Error al guardar item");
                    } else {
                        self.loading = false;
                        self.$toastr.s("Item guardado exitosamente", "Correcto");
                        self.$emit('entity', response.data);

                        if(!self.isModal) {
                            self.volver();
                        }
                    }
                })
                .catch(function (error) {
                    self.$emit('update:save', false);
                    self.$toastr.e(error, "Exception");
                });
            }
        }
    },
    beforeMount: function() {
        const self = this;

        const apis = [
            self.axios.get(`periodicities/list`, {
               params: {
                    'simple' : 1
                }
            }),
            self.axios.get(`time-units/list`)
        ];
        
        let callback = (dataPeriodicities, dataTimeUnits) => {
            self.periodicities = dataPeriodicities.data.items;
            self.timeUnits = dataTimeUnits.data;
        };

        if(self.$route.params.id) {
            self.estaEditando = true;
            self.titulo = 'Editar item';

            apis.push(
                self.axios.get(`items/${self.$route.params.id}`)
            );

            callback =  (dataPeriodicities, dataTimeUnits, dataItem) => {
                self.periodicities = dataPeriodicities.data.items;
                self.timeUnits = dataTimeUnits.data;

                self.form.id = dataItem.data.id;
                self.form.name = dataItem.data.name;
                self.form.periodicity_id = dataItem.data.periodicity_id;
                self.form.event_numbers = dataItem.data.event_numbers;
                self.form.has_warning_time = dataItem.data.has_warning_time;
                self.form.warning_time_quantity = dataItem.data.warning_time_quantity;
                self.form.warning_time_unit_id = dataItem.data.warning_time_unit_id;
            };
        }

        self.axios.all(apis)
        .then(self.axios.spread(callback))
        .catch((err) => {
            console.log('error', err);
            self.$toastr.e(err.message, "Exception");
        });
	}
}
</script>

<style>
</style>